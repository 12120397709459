.app__about{
    flex: 1;
    width: 100%;
    flex-direction: column;
    .head-text h2{
     font-size: 2rem;
       
    }

    .head-text h3{
        font-size: 1.5rem;
          
       }
    
}


.app__profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

    
 
     &-head-text{
        font-size: 2.75rem;
        font-weight: 800;
        text-align: center;
        color: var(--black-color);
        margin-bottom: 4rem;

        span{
            color: var(--main-bg-color);
        }
        
    }
    &-p-text{
        width: 35rem;
        line-height: 2;
        text-align: justify;
        padding: 1rem;

        @media screen and (max-width: 450px) {
            width: auto;
          }
    }

}




.app__profile-item{
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    &:hover{
        cursor: pointer;
    }

    img{
        width: 100%;
        height: 170px;
        border-radius: 50px;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;

        img{    
            height: 320px;
        }
        
    }
}