.app__social_bar{
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 1rem;
    position: fixed;
    z-index: 2;

    div {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--white-color);
        margin: 0.25rem 0;
        border: 1px solid var(--lightGray-color);
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        transition: all 0.3s ease-in-out;
    
        svg {
          width: 20px;
          height: 20px;
          color: var(--secondary-color);
          vertical-align: top;
        }
    
        &:hover {
          background-color: var(--secondary-color);
          border-color: var(--secondary-color);
    
          svg {
            color: var(--white-color);
          }
        }
    
        @media screen and (min-width: 2000px) {
          width: 70px;
          height: 70px;
    
          margin: 0.5rem 0;
    
          svg {
            width: 30px;
            height: 30px;
          }
        }
        @media screen and (max-width: 900px){
          display: none;
      }
      }
}

